.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid var(--gray);

  padding: 0 10% 15px 10%;
  min-height: 30vh;

  img {
    width: 150px;
    margin-right: 100px;
  }
}

@media screen and (max-width: 800px) {
  .container {
    flex-direction: column-reverse;
    padding: 10% 0;
    text-align: center;

    img {
      margin: 10% 0 0 0 ;
    }
  }
}