.container {
  width: 90%;
  max-width: 400px;
  border: 2px solid var(--gray);
  padding: 10px;
  border-radius: 15px;
  margin: 10px 0;
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    border-radius: 15px;
  }
  h3 {
    margin: 5px;
  }
}