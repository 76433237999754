.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5% 20%;

  border-top: 2px solid var(--gray);

  .container2 {
    display: flex;
    flex-direction: column;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 4rem;
  }

  p {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 800px) {
  .container {
    padding: 5% 5%;
  }
}