.container {
  width: 100%;
  height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: url("../../images/pozadina3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  //background-position-y: -250px;

  h1 {
    font-size: 6rem;
    text-align: center;
    margin: 0;
  }

  p {
    text-align: center;
    margin: 0;
    letter-spacing: 6.5px;
    font-weight: bold;
  }

  .btn {
    margin-top: 20px;
    padding: 10px 30px;
    letter-spacing: 3px;
    color: black;
    font-weight: bold;
    background: var(--blue);
    transition: all 0.5s ease;
    &:hover {
      letter-spacing: 6.5px;
    }
  }

  img {
    width: 70px;
  }
}