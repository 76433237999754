.container {
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 4rem;
  }

  p {
    margin-bottom: 0;
    text-align: justify;
    font-size: 1.0rem;
  }

  .lj {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }

  flex-direction: column;
  padding: 5% 20%;

}

@media screen and (max-width: 800px) {
  .container {
    padding: 5% 5%;
  }
}