.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 2px solid var(--gray);
  padding: 5% 0;

  h1 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 4rem;
  }

  form {
    width: 83%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
   
    .lj {
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }

    p {
      margin: 5px 0;
    }

    label {
      margin: 10px 0 5px 0;
    }

    input {
      padding: 10px;
      border-radius: 10px;
      border: 2px solid var(--gray);
    }

    .btn {
      margin-top: 20px;
      padding: 10px 30px;
      letter-spacing: 3px;
      color: black;
      font-weight: bold;
      background: var(--blue);
      transition: all 0.5s ease;
      &:hover {
        letter-spacing: 6.5px;
      }
    }

    h3 {
      font-size: 1rem;
    }

    #success {
      color: green;
    }

    #error {
      color: #b60606;
    }

  }

}