@import "~react-image-gallery/styles/scss/image-gallery.scss";

.container {
  border-top: 2px solid var(--gray);
  padding: 5% 0;

  h1 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 4rem;
  }
}