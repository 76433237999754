@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

html {
  scroll-behavior: smooth;
}

* {
  font-family: "Jost", sans-serif;
  --white: #F7F7F9;
  --gray: #e8e4e4;
  --black: #0B0014;
  --darkGray: #302c2c;
  --red: #D44D5C;
  --blue: #f3cc53;
}
