.container {
  width: 100%;
  height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  //background: url("../../images/pozadina3.jpg");
  //filter: blur(8px);
  //-webkit-filter: blur(8px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center !important;
  z-index: 1;



}

.headingBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
  color: white;
  h1 {
    font-size: 5rem;
    margin: 0;
  }

  p {
    margin: 0;
    letter-spacing: 6.5px;
    font-weight: bold;
  }
  .btn {
    margin-top: 20px;
    padding: 10px 30px;
    letter-spacing: 3px;
    color: black;
    font-weight: bold;
    background: var(--blue);
    transition: all 0.5s ease;
    &:hover {
      letter-spacing: 6.5px;
    }
  }
}

#paragraf {
  font-size: 1.3rem;
  margin: 30px 10%;
}

@media screen and (max-width: 800px) {
  .headingBox {
    h1 {
      font-size: 2.5rem;
      margin-bottom: 10px;
    }
  }
}