.header {
  z-index: 500;
  top: 0;
  position: fixed;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  /*padding: 0 15%;*/
  width: 100%;
  height: 100px;
  transition: all .5s ease;

  .menu {
    display: none;
  }

  .logo {
    margin-left: 15%;
    font-weight: 600;
    font-size: 2rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 45px;
      margin-right: 10px;
    }
  }

  span {
    /*color: var(--red);*/
  }

  .navigation {
    display: flex;
    list-style: none;
    margin-right: 15%;

    a {
      margin: 0 10px;
      color: white;
      font-weight: 600;
    }
  }
}

.header.scroll {
  box-shadow: 0 0 40px -26px gray;
  background: white;
  a {
    color: var(--darkGray);
  }
  .menu {
    .line {
      background: var(--darkGray) !important;
    }
  }
}

a {
  text-decoration: none;
}

@media screen and (max-width: 800px) {
  .header {
    align-items: center;
    text-align: center;
    height: 80px;

    .logo {
      margin-left: 27.6px;
      font-weight: 600;
    }

    .menu {
      display: flex;
      margin-right: 27.6px;
      width: 40px;
      height: 23px;
      flex-direction: column;
      justify-content: space-between;

      .line {
        visibility: visible;
        opacity: 1;
        border-radius: 2px;
        //background: var(--darkGray);
        background: white;
        height: 4px;
        width: 30px;
        transition: all .3s ease;
      }
    }

    .menu.clicked {
      justify-content: center;
      align-items: center;

      #secondLine {
        visibility: hidden;
        opacity: 0;
      }

      #firstLine {
        position: absolute;
        //width: 42.42px;
        transform: rotateZ(45deg);
      }

      #thirdLine {
        position: absolute;
        //width: 42.42px;
        transform: rotateZ(135deg);
      }
    }

    .navigation {
      position: fixed;
      top: -200px;
      visibility: hidden;
      opacity: 0;
      list-style: none;
      margin-right: 15%;
      transition: 0.3s ease;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 0;
      background: white;
      margin: 0;

      li {
        //padding: 20px 0;
        height: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
          margin: 0;
          color: var(--darkGray);
          font-weight: 600;
        }
      }
    }

    .navigation.shown {
      top: 80px;
      visibility: visible;
      opacity: 1;
      border-top: 3px solid var(--gray);
    }
  }
}